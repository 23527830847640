.word-sheet {
  .word-line {
    page-break-inside: avoid;
    .word {
      //   font-family: "华文细黑";
      font-size: 5mm;
    }
    .line-wrapper {
      margin-bottom: 6mm;
      &:last-child {
        margin-bottom: 0;
      }
      .line {
        margin-bottom: 3mm;
        border-bottom: 1px solid black;
        &:nth-child(3) {
          border-bottom-color: darkred;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
