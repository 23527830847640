$cellWidth: 10mm;
$cellCount: 20;
body {
  background-color: #333 !important;
}

.panel {
  position: fixed;
  right: 0;
  padding: 20px;

  display: flex;
  flex-direction: column;
  width: 640px;
  background-color: #444;
  height: 100vh;
  border-left: 1px solid gray;
  z-index: 20;
}
.panel.print {
  display: none;
}
.dark.para-con {
  background-color: #333;
  color: #fff;
  .para-cell {
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-left: transparent;
    border-right: transparent;
  }
}
.para-con {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc($cellCount * $cellWidth);
  overflow: visible;
  .line-appendix {
    font-family: 楷体;
    padding-top: $cellWidth / 2;
    font-size: $cellWidth * 0.67;
    letter-spacing: -$cellWidth / 4;
  }
  .para-cell {
    position: relative;
    margin-top: $cellWidth * 0.6;
    width: $cellWidth;
    height: $cellWidth;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-left: transparent;
    border-right: transparent;
    font-family: 楷体, 楷体-简;
    font-size: $cellWidth * 0.67;
    text-align: center;
    line-height: $cellWidth;
    margin-right: -1px;
    user-select: none;
    &.leftP::before {
      content: "(";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-1.2mm);
    }
    &.rightP::after {
      content: ")";
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(1.2mm);
    }
    &.py-in-grid .char {
      display: none;
    }
    &.py-in-grid .py {
      transform: translateX(-50%) translateY(0);
    }
    .py {
      position: absolute;
      top: 0;
      font-family: pyfont;
      font-size: $cellWidth * 0.4;
      left: 50%;
      transform: translateX(-50%) translateY(-$cellWidth * 0.8);
    }
    .append {
      position: absolute;
      left: $cellWidth;
      top: 0;
    }
  }
}
