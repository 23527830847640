.panel {
  position: fixed;
  right: 0;
  padding: 20px;

  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: white;
  height: 100vh;
  border-left: 1px solid gray;
  z-index: 20;
}
.panel.print {
  display: none;
}
.order {
  width: 350px;
  height: auto;
}
.no-pinyin .py {
  display: none;
}
.cell {
  border: 0.5mm solid black;
  display: inline-block;
  margin-bottom: 1mm;
  position: relative;
}
.cell .py {
  border-bottom: 0.5mm solid black;
}
.cell .py .content {
  position: absolute;
  font-family: Consolas;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 00.5mm);
  font-size: 20px;
  line-height: 20px;
}
.cell .py .line {
  margin-top: 2.5mm;
  margin-bottom: 2.5mm;
  border-bottom: 0.5mm dashed gray;
}
.cell:not(:nth-child(14n + 1)) {
  border-left: none;
}
.cell .char {
  width: 16mm;
  height: 16mm;
  position: relative;
}
.cell .char .body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 楷体;
  font-size: 12mm;
  color: rgba(0, 0, 0, 0.3);
  width: 80%;
  height: 80%;
}
.cell.primary .char .body {
  color: black;
}
.cell .char .h-grid {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(50% - 0.25mm);
  border-bottom: 0.5mm dashed gray;
}
.cell .char .v-grid {
  position: absolute;
  height: 100%;
  left: calc(50% - 0.25mm);
  top: 0;
  border-left: 0.5mm dashed gray;
}
.line {
  width: auto;
}
.char-wrapper {
  page-break-inside: avoid;
  display: flex;
  flex-wrap: wrap;
  width: calc(15mm * 14);
}
